<template>
  <div>
    <div v-show="!isLoaded || isUpdate">
      <spin-loader />
    </div>
    <div v-show="isLoaded && !isUpdate">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div v-if="hasPermissionToRead">
            <b-card
              no-body
              class="mb-0"
            >
              <div class="m-2">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <div>
                      <b-form-group
                        label="Exibir"
                        label-for="idSearchBox"
                        class="mb-0"
                      >
                        <v-select
                          id="idSearchBox"
                          v-model="perPage"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="perPageOptions"
                          :clearable="false"
                          class="per-page-selector d-inline-block"
                        >
                          <span slot="no-options">Não há opções para essa quantidade.</span>
                        </v-select>
                      </b-form-group>
                    </div>
                    <b-form-input
                      v-model.lazy="searchQuery"
                      placeholder="Buscar"
                      type="text"
                      class="d-inline-block mt-2 ml-2"
                    />
                  </b-col>
                </b-row>
              </div>
              <b-table
                id="users_table"
                class="position-relative"
                primary-key="uuid"
                responsive
                show-empty
                striped
                hover
                empty-text="Não há usuários listados."
                :items="users"
                :fields="tableColumns"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
              >

                <template #cell(field_name)="data">
                  <b-media>
                    <template #aside>
                      <b-avatar
                        :src="userImages[data.item.uuid]"
                        size="2.5rem"
                      />
                    </template>
                    <div
                      class="font-weight-bold text-nowrap biddingOwnerField mt-50"
                    >
                      {{ data.item.profile.get_full_name }}
                    </div>
                  </b-media>
                </template>

                <template #cell(field_profile)="data">
                  <b-media vertical-align="center">
                    <template v-if="data.item.profile.permission_set">
                      {{ data.item.profile.permission_set.name }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </b-media>
                </template>

                <template #cell(field_actions)="field">
                  <div class="d-flex justify-content-end">
                    <b-button
                      class="btn-icon rounded-circle"
                      variant="flat-info"
                      size="md"
                      @click="showConfirmOrCancelDelete(field.item)"
                    >
                      <svg-icon
                        type="mdi"
                        size="18"
                        :path="mdiTrashCanOutline"
                      />
                    </b-button>
                  </div>
                </template>

              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalItems"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <div>
        <b-button
          id="addAdministratorBtn"
          v-b-tooltip.hover
          class="btn-icon rounded-circle m-3"
          variant="primary"
          size="md"
          :disabled="!hasPermissionToWrite"
          title="Adicionar Administrador"
          @click="btnCreateAdmin()"
        >
          <svg-icon
            type="mdi"
            :path="mdiPlus"
          />
        </b-button>
      </div>
    </div>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <b-modal
        id="idModalCreateAdministrator"
        ref="modalCreateAdministrator"
        no-close-on-backdrop
        no-close-on-esc
        centered
        cancel-variant="outline-primary"
        :ok-disabled="invalid"
        @ok="handleSubmit(createAdmin)"
        @cancel="cancel()"
        @hidden="cancel()"
      >
        <template #modal-title>
          Cadastro de Administrador
        </template>
        <template #modal-cancel>
          Cancelar
        </template>
        <template #modal-ok>
          Salvar
        </template>
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <owner-select
                id="owner"
                v-model="user"
                label-field="Nome"
                :readonly="false"
                :required-field="true"
                :only-active="true"
                :only-admin="false"
                @ready="syncLoad.ready('owner')"
              />
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BTable,
  BMedia,
  BButton,
  BPagination,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'

import {
  ValidationObserver,
} from 'vee-validate'

import SvgIcon from '@jamescoyle/vue-icon'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import router from '@/router'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import SyncLoad from '@/components/utils/syncLoad'
import OwnerSelect from '@/components/user/OwnerSelect.vue'

export default {
  components: {
    BAvatar,
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BMedia,
    BButton,
    BPagination,
    SvgIcon,
    vSelect,
    SpinLoader,
    ValidationObserver,
    OwnerSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    const profile = syslic.authentication.getProfile()

    const perPage = 10
    const totalItems = 0
    const currentPage = 1
    const searchQuery = ''
    const sortBy = 'name'
    const isSortDirDesc = false
    const perPageOptions = [10, 25, 50, 100]

    const tableColumns = [
      { key: 'field_name', label: 'Nome', sortable: false },
      { key: 'field_profile', label: 'Perfil', sortable: false },
      { key: 'field_actions', label: '', sortable: false },
    ]

    const users = []
    const userImages = {}

    const user = null

    const isLoaded = false
    const isUpdate = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('users_list')
    syncLoad.addTrigger('verify_permissions')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    return {
      profile,
      perPage,
      totalItems,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      perPageOptions,
      tableColumns,
      users,
      userImages,
      user,
      isLoaded,
      isUpdate,
      syncLoad,
    }
  },
  computed: {
    hasPermissionToRead() {
      return this.profile.get_permissions_for_modules.admin.can_read
    },
    hasPermissionToUpdate() {
      return this.profile.get_permissions_for_modules.admin.can_edit
    },
    hasPermissionToWrite() {
      return this.profile.get_permissions_for_modules.admin.can_write
    },
  },
  watch: {
    perPage: 'fetchData',
    currentPage: 'fetchData',
    searchQuery: function updateSearchQuery(value) {
      this.currentPage = 1
      this.searchQuery = value
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
    this.verifyPermissions()
  },
  methods: {
    fetchData() {
      syslic
        .user
        .fetchUsersList({
          p: this.currentPage,
          page_size: this.perPage,
          q: this.searchQuery,
          is_admin: true,
        })
        .then(response => {
          this.totalItems = response.data.count
          this.users = response.data.results
          return this.loadUsersPhotos(this.users)
        })
        .then(() => {
          this.isUpdate = false
          this.syncLoad.ready('users_list')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao ler os dados dos usuários.',
              text: 'Não foi possível ler os dados do usuários do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.isUpdate = false
          this.syncLoad.ready('users_list')
        })
    },
    loadUsersPhotos(users) {
      const promiseList = []
      users.forEach(user => {
        const promise = new Promise((resolve, reject) => {
          syslic.user.getUserImage(user.uuid)
            .then(response => {
              this.userImages[user.uuid] = response
              resolve()
            })
            .catch(() => {
              reject()
            })
        })

        promiseList.push(promise)
      })

      return Promise.allSettled(promiseList)
    },
    verifyPermissions() {
      if (this.hasPermissionToRead || this.hasPermissionToUpdate) {
        this.syncLoad.ready('verify_permissions')
      } else {
        router.push({
          name: 'home',
        })
      }
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você deseja remover a permissão de administrador deste usuário.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteAdmin(item)
          }
        })
    },
    deleteAdmin(item) {
      const params = {
        is_admin: false,
      }
      syslic
        .user
        .updateUserIsAdmin(item.profile.id, params)
        .then(() => {
          this.users.splice(this.users.indexOf(item), 1)
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Permissão de administrador removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          const textError = 'Não foi possível remover a permissão de administrador deste usuário, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover a permissão de administrador.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    btnCreateAdmin() {
      this.user = null
      this.$refs.modalCreateAdministrator.show()
    },
    createAdmin() {
      this.isUpdate = true
      const params = {
        is_admin: true,
      }
      syslic
        .user
        .updateUserIsAdmin(this.user.id, params)
        .then(() => {
          this.fetchData()

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Permissão de administrador adicionada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          const textError = 'Não foi possível adicionar a permissão de administrador, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar a permissão de administrador.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.isUpdate = false
        })
    },
    cancel() {
      this.$refs.modalCreateAdministrator.hide()
      this.user = null
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPlus,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#addAdministratorBtn {
  position: fixed;
  right: 0;
  bottom: 0;
}

#idModalCreateAdministrator .vc-slider {
  width: 100% !important;
}

#idModalCreateAdministrator .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: end;

  .btn{
    min-width: 107px;
  }
}
</style>
